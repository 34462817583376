import React from "react";
import "./Footer.scss";
import nisoftLogo from "../assets/images/logo.png";

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-company">
        <div className="footer-logo">
          <img src={nisoftLogo} alt="FooterLogo" />
        </div>
        <div className="footer-title">엔아이소프트(주)</div>
        <div className="footer-detail">
          <p>
            Address. 서울특별시 동작구 상도로 369, 창신관 108-109호 (상도동,
            숭실대학교)
          </p>
          <p>Tel. 070 4304 0092</p>
          <p>Email. master@nisoft.kr</p>
        </div>
      </div>
      <div className="footer-info">

      </div>
    </footer>
  );
}

export default Footer;
